import React from 'react';

const TOUR_DATES = [
  {
    date: 'jan 17',
    location: 'el centro, ca'
  }, {
    date: 'jan 22',
    location: 'villiers-le-bel, france'
  }, {
    date: 'feb 7',
    location: 'san francisco, ca'
  }, {
    date: 'feb 17',
    location: 'berkeley, ca'
  }, {
    date: 'mar 5',
    location: 'nimes, france'
  }, {
    date: 'mar 25',
    location: 'albuquerque, nm'
  }, {
    date: 'jun 1',
    location: 'st. louis, mo'
  }, {
    date: 'jun 21',
    location: 'los gatos, ca'
  }, {
    date: 'jul 22',
    location: 'poitiers, france'
  }, {
    date: 'oct 19',
    location: 'san mateo, ca'
  }, {
    date: 'nov 11',
    location: 'beverly hills, ca'
  }, {
    date: 'nov 24',
    location: 'albuquerque, nm'
  }, {
    date: 'dec 17',
    location: 'evanston, il'
  }, {
    date: 'dec 22',
    location: 'clamart, france'
  }, {
    date: 'dec 27',
    location: 'paris, france'
  }
];

function TourDates () {
  const renderDates = () => {
    return TOUR_DATES.map((singleDate, index) => {
      return (
        <p className='table-row' key={index}>
          <span className='table-left'> {singleDate.date} </span>
          <span className='table-right'> {singleDate.location} </span>
        </p>
      )
    })
  }

  const renderHeader = () => {
    const year = new Date().getFullYear()
    return (
      <h3>
        {`Tour Dates ${year}`}
      </h3>
    )
  }

  return (
    <div className='app-row tour-dates' id='tour-dates'>
      <div>
        { renderHeader() }
        <div className='tour-dates-table'>
          { renderDates() }
        </div>
      </div>
    </div>
  );
}

export default TourDates;