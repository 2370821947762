import React from 'react';

function Navigation() {
  const [isOpen, setIsOpen] = React.useState(false);

  const renderMobileNav = () => {
    if (isOpen) {
      return (
        <ul className='mobile-items'>
          <li>
            <a href='#landing-page'>Home</a>
          </li>
          <li>
            <a href='#biography'>About</a>
          </li>
          <li>
            <a href='#tour-dates'>Tour Dates</a>
          </li>
          <li>
            <a href='#new-music'>New Music</a>
          </li>
        </ul>
      )
    }
  }

  return (
    <div className='navigation-wrapper'>
      <div className='hamburger-menu' onClick={() => setIsOpen(!isOpen)}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>

      { renderMobileNav() }

      <ul className='desktop-items'>
        <li>
          <a href='#landing-page'>Home</a>
        </li>
        <li>
          <a href='#biography'>About</a>
        </li>
        <li>
          <a href='#tour-dates'>Tour Dates</a>
        </li>
        <li>
          <a href='#new-music'>New Music</a>
        </li>
      </ul>
    </div>
  );
}

export default Navigation;