import React from 'react';

function Biography() {
  return (
    <div className='app-row biography-wrapper'  id='biography'>
      <div className='biography-content'>
        <h3>About</h3>
        <p>
          Hailing from the shores of Paris, this techno don got his start in the basements of bars on the Champs-Élysées. Initially DJing only Ska and German Polka, the lackluster response to his accordion beats nearly killed his DJ career. While on tour in the USA, he attended his first EDM event at the Bungalow in sunny Santa Monica, CA and immediately fell in love with tropical house. He spun the likes of Kygo and Thomas Jack up and down the dive bars of the OC.
        </p>
        <br />
        <p>
          In 2021 he attended his first warehouse party and immediately his life was changed forever. He quickly traded in steel drums and pan flutes for 808s and sequencers. His first gig at the Cabo Cantina in West Hollywood, CA was a huge flop (didn’t know how to read a room) but he quickly turned it around and started playing the festival circuit in Bakersfield. Today you can find him roaming the dive bars and basements of Fresno bringing joy one untz at a time.
        </p>
      </div>
    </div>
  );
}

export default Biography;