import React from 'react';

function NewMusic () {
  return (
    <div className='new-music' id='new-music'>
      <div>
        <h3>New Music</h3>
        <iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/track/0hxZQue4l6jfZGiae1mHDL?utm_source=generator&theme=0" width="60%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </div>
    </div>
  );
}

export default NewMusic;