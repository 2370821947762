import './App.css';
import Biography from './components/biography.js';
import LandingPage from './components/landingPage.js';
import Navigation from './components/navigation.js';
import NewMusic from './components/newMusic.js';
import TourDates from './components/tourDates.js';

function App() {
  return (
    <div className="App">
      <Navigation />
      <LandingPage />
      <Biography />
      <TourDates />
      <NewMusic />
    </div>
  );
}

export default App;
