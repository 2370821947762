import React from 'react';
import kevBackground from '../assets/kev_hero_image.png'
import useSound from 'use-sound';
import music from '../assets/sound/chumpLoop.mp3';

function LandingPage () {
  const [play, { stop }] = useSound(music);
  const [playMusic, setPlayMusic] = React.useState(false)

  React.useEffect(() => {
    if (playMusic) {
      play()
    } else {
      stop()
    }
  }, [play, playMusic, stop]);

  return (
    <div className='landing-page app-row' id='landing-page'>
      <div>
        <img id='hero-image-desktop' src={kevBackground} alt='hero background' />
        <button onClick={() => {setPlayMusic(!playMusic)}}>
          { playMusic ? 'X Stop' : '[ play ]' }
        </button>
      </div>
    </div>
  );
}

export default LandingPage;